
import { useEcharts } from "@/hooks";
import {
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
} from "vue";
export default defineComponent({
  name: "EnrollmentChannelsChart",
  setup() {
    const loading = ref(true);
    const channelsChart = ref<HTMLDivElement | null>(null);
    const channelsChart2 = ref<HTMLDivElement | null>(null);
    const init = () => {
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          orient: 'vertical', // 设置为 'vertical' 以使图例垂直排列
          right: 0, // 设置为 0 以将图例放置在右边
        },
        grid: {
          left: '1%',
          right: '4%',
          bottom: '20px',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: ['未到期', '365天', '182天', '90天', '30天', '7天'],
          show: true, // 确保轴本身是可见的
          axisTick: {
            show: false, // 设置为 false 以隐藏轴刻度
          },
          axisLine: {
            show: false, // 设置为 false 以隐藏轴刻度
          },
        },
        yAxis: {
          show: false,
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        series: [
          {
            name: '未授权',
            type: 'bar',
            itemStyle: {
              color: '#0066FF', // 设置这个柱子的颜色为红色
            },
          },
          {
            name: '已到期',
            type: 'bar',
            barWidth: '40%',
            itemStyle: {
              color: '#FFACAC', // 设置这个柱子的颜色为红色
              borderRadius: [5, 5, 0, 0]
            },
            data: [
              {
                value: 10,
                itemStyle: {
                  color: '#0066FF', // 设置这个柱子的颜色为蓝色

                },
              }, 10, 20, 30, 40, 50, 60]
          }

        ]
      };
      const option2 = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          orient: 'vertical', // 设置为 'vertical' 以使图例垂直排列
          right: 0, // 设置为 0 以将图例放置在右边
        },
        grid: {
          left: '1%',
          right: '10%',
          bottom: '20px',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: ['未到期', '365天', '182天', '90天', '30天', '7天'],
          show: true, // 确保轴本身是可见的
          axisTick: {
            show: false, // 设置为 false 以隐藏轴刻度
          },
          axisLine: {
            show: false, // 设置为 false 以隐藏轴刻度
          },
        },
        yAxis: {
          show: false,
          type: 'value',
          boundaryGap: [0, 0.01]
        },

        series: [
          {
            name: '未授权',
            type: 'bar',
            itemStyle: {
              color: '#0066FF', // 设置这个柱子的颜色为红色
            },
          },
          {
            name: '已到期',
            type: 'bar',
            barWidth: '40%',
            itemStyle: {
              color: '#FFACAC', // 设置这个柱子的颜色为红色
              borderRadius: [5, 5, 0, 0]
            },
            data: [
              {
                value: 10,
                itemStyle: {
                  color: '#0066FF', // 设置这个柱子的颜色为蓝色

                },
              }, 10, 20, 30, 40, 50, 60]
          }

        ]
      };

      setTimeout(() => {
        loading.value = false;
        nextTick(() => {
          useEcharts(channelsChart.value as HTMLDivElement).setOption(option);
          useEcharts(channelsChart2.value as HTMLDivElement).setOption(option2);
        });
      }, 100);
    };
    const updateChart = () => {
      useEcharts(channelsChart.value as HTMLDivElement).resize();
    };
    onMounted(init);
    return {
      loading,
      channelsChart,
      channelsChart2,
      updateChart,
    };
  },
});
